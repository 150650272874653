<template>
  <div>
    <div class="oa-work-box border">
      <span>
      </span>
      <span>
        <el-date-picker v-model="searchOption.date" type="month" size="mini" placeholder="请选择时间" style="margin-right:10px;"></el-date-picker>
        <el-input v-model="searchOption.key" class="search-input" size="mini" placeholder="请输入名称搜索">
        </el-input>
        <el-button type="primary" size="mini" @click="search">
          <font-awesome-icon fas icon="search"></font-awesome-icon>&nbsp;查询
        </el-button>
      </span>
    </div>
    <!-- 主体内容 -->
    <el-table border stripe :data="list" size="mini" class="oa-table">
      <el-table-column width="50" align="center" type="index" label="#"></el-table-column>
      <el-table-column prop="UserName" label="姓名"></el-table-column>
      <el-table-column prop="LoginTime" label="登录"></el-table-column>
    </el-table>
    <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" @size-change="pageSizeChange"
        @current-change="pageIndexChange">
      </el-pagination>
  </div>
</template>

<script>
import API from '../../apis/ticket-api'
import { LOGIN_LOG } from '../../router/base-router'

export default {
  name: LOGIN_LOG.name,
  data () {
    return {
      searchOption: {
        key: '', // 搜索条件（关键字）
        date: ''
      },
      total: 1, // 总数据量
      pageIndex: 1, // 页码
      pageSize: 50, // 页数
      list: [] // 列表
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.get())
  },
  methods: {
    get () {
      this.loading = true
      this.axios.get(`${API.LOGIN_LOG}/${this.pageIndex}/${this.pageSize}`, {
        params: this.searchOption
      }).then(response => {
        this.total = response.Total
        this.list = response.Items
        this.loading = false
      })
    },
    pageSizeChange (value) {
      this.pageSize = value
      this.$nextTick(() => this.get())
    },
    pageIndexChange (value) {
      this.pageIndex = value
      this.$nextTick(() => this.get())
    },
    search () {
      this.pageIndex = 1
      this.$nextTick(() => this.get())
    }
  },
  created () {
    this.get()
  }
}
</script>

<style lang="scss" scoped>
/deep/ .oa-work-box {
  .search-input {
    width: 200px;
    margin-right: .75rem;
  }

  .type-select {
    width: 100px;
    margin-right: .75rem;
  }

}
</style>
